import { Routes, Route } from "react-router-dom";
import "./App.css";
import "./fontello/css/fontello.css";
import Home from "./presentation/Home/Home";
import MyPorTech from "./myportech/MyPorTech";
import AndyAguilarPortfolio from "./myportech/portfolios/AndyAguilar/AndyAguilarPortfolio";
import EventGallery from "./myportech/EventGallery/EventGallery";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/myportech" element={<MyPorTech />} />
      <Route
        path="/myportech/andy-aguilar"
        element={<AndyAguilarPortfolio />}
      />
      <Route
        path="/myportech/andy-aguilar/event/:eventName"
        element={<EventGallery />}
      />
    </Routes>
  );
}

export default App;
