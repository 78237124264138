import React, { useEffect } from "react";
import "./About.css";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="about-container" id="about">
      <div className="about-content">
        <h2>{t("About")} </h2>
        <p>{t("About-txt1")}</p>
        <p>{t("About-txt2")}</p>
        <p>{t("About-txt3")}</p>
      </div>
    </div>
  );
};

export default About;
