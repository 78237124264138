import React, { useState } from "react";
import "./Services.css";
import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";
import { useTranslation } from "react-i18next";

const Services = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  const services = [
    {
      title: t("service1Title"),
      description: t("service1Description"),
      iconClass: "icon-star",
    },
    {
      title: t("service2Title"),
      description: t("service2Description"),
      iconClass: "icon-desktop",
    },
    {
      title: t("service3Title"),
      description: t("service3Description"),
      iconClass: "icon-mobile",
    },
  ];

  // Función para manejar la visibilidad del componente
  const handleChangeVisibility = (visible) => {
    if (visible) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={handleChangeVisibility} partialVisibility>
      <section id="services" className="services-section">
        <div className="container">
          <h2 className="section-title">{t("servicesTitle")}</h2>
          <div className="services-list">
            {isVisible &&
              services.map((service, index) => (
                <Fade key={index} delay={index * 200}>
                  <div className="service-item">
                    <span
                      className={`service-icon ${service.iconClass}`}
                    ></span>{" "}
                    <h3 className="service-title">{service.title}</h3>
                    <p className="service-description">{service.description}</p>
                  </div>
                </Fade>
              ))}
          </div>
        </div>
      </section>
    </VisibilitySensor>
  );
};

export default Services;
