import React, { useState } from "react";
import "./Quote.css";
import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";
import { useTranslation } from "react-i18next";

const Quote = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const handleChangeVisibility = (visible) => {
    if (visible) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={handleChangeVisibility} partialVisibility>
      <div className="quote-section" id="quote">
        <div className="container">
          <Fade bottom cascade when={isVisible}>
            <h2>{t("quoteSectionTitle")}</h2>

            <div className="service-cards">
              <div className="service-card">
                <h3>{t("myPortechTitle")}</h3>
                <p>{t("myPortechDescription")}</p>
                <p>{t("myPortechDescription2")}</p>
                <a href="#contact" className="btn">
                  {t("getQuoteButton")}
                </a>
              </div>

              <div className="service-card">
                <h3>{t("staticWebsiteTitle")}</h3>
                <p>{t("staticWebsiteDescription")}</p>
                <p>{t("contactOptionsIncluded")}</p>
                <p>{t("postLaunchMaintenance")}</p>
                <a href="#contact" className="btn">
                  {t("getQuoteButton")}
                </a>
              </div>

              <div className="service-card">
                <h3>{t("webAppTitle")}</h3>
                <p>{t("webAppDescription")}</p>
                <p>{t("contactOptionsIncluded")}</p>
                <p>{t("postLaunchMaintenance")}</p>
                <a href="#contact" className="btn">
                  {t("getQuoteButton")}
                </a>
              </div>
            </div>

            <p>{t("customSolution")}</p>
            <a href="#contact">{t("contactForQuote")}</a>

            <div className="workflow">
              <h2>{t("workflowTitle")}</h2>
              <div className="steps-container">
                <div className="workflow-step">
                  <span className="icon-chat"></span>
                  <p className="step-title">
                    {t("workflowSteps.initialConsultation.title")}
                  </p>
                  <p>{t("workflowSteps.initialConsultation.description")}</p>
                </div>
                <div className="workflow-step">
                  <span className="icon-time"></span>
                  <p className="step-title">
                    {t("workflowSteps.setTimes.title")}
                  </p>
                  <p>{t("workflowSteps.setTimes.description")}</p>
                </div>
                <div className="workflow-step">
                  <span className="icon-spinner"></span>
                  <p className="step-title">
                    {t("workflowSteps.designDevelopment.title")}
                  </p>
                  <p>{t("workflowSteps.designDevelopment.description")}</p>
                </div>
                <div className="workflow-step">
                  <span className="icon-search"></span>
                  <p className="step-title">
                    {t("workflowSteps.reviewChanges.title")}
                  </p>
                  <p>{t("workflowSteps.reviewChanges.description")}</p>
                </div>
                <div className="workflow-step">
                  <span className="icon-eye"></span>
                  <p className="step-title">
                    {t("workflowSteps.testing.title")}
                  </p>
                  <p>{t("workflowSteps.testing.description")}</p>
                </div>
                <div className="workflow-step">
                  <span className="icon-pin"></span>
                  <p className="step-title">
                    {t("workflowSteps.deployment.title")}
                  </p>
                  <p>{t("workflowSteps.deployment.description")}</p>
                </div>
                <div className="workflow-step">
                  <span className="icon-work"></span>
                  <p className="step-title">
                    {t("workflowSteps.supportMaintenance.title")}
                  </p>
                  <p>{t("workflowSteps.supportMaintenance.description")}</p>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default Quote;
