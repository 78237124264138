// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpApi) // Permite cargar las traducciones desde un backend
  .use(LanguageDetector) // Detecta automáticamente el idioma del navegador
  .use(initReactI18next) // Se conecta con React
  .init({
    fallbackLng: "en", // Idioma por defecto
    debug: true,
    interpolation: {
      escapeValue: false, // React ya hace escaping por defecto
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
  });

export default i18n;
