import React from "react";
import "./SocialMedia.css";
import { useTranslation } from "react-i18next";

const SocialMedia = () => {
  const { t } = useTranslation();

  return (
    <section id="socialMedia" className="redes-sociales-section">
      <div className="container">
        <h2 className="section-title">{t("socialMediaTitle")}</h2>

        <div className="social-links">
          <a
            href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61559806475226"
            className="social-link"
          >
            <span className="icon-facebook"></span>
            Facebook
          </a>

          <a
            href="https://www.instagram.com/lucchino_tech/"
            className="social-link"
          >
            <span className="icon-instagram"></span>
            Instagram
          </a>

          <a href="https://x.com/Luccino_Tech" className="social-link">
            <span className="icon-twitter"></span>
            Twitter
          </a>

          <a
            href="https://www.linkedin.com/company/luccino-tech"
            className="social-link"
          >
            <span className="icon-linkedin"></span>
            Linkedin
          </a>
        </div>
      </div>
    </section>
  );
};

export default SocialMedia;
