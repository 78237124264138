import React, { useEffect, useState, useRef } from "react";

const LazyImage = ({ src, alt, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const imgObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        imgObserver.disconnect();
      }
    });
    imgObserver.observe(imgRef.current);

    return () => imgObserver.disconnect();
  }, []);

  return <img ref={imgRef} src={isVisible ? src : ""} alt={alt} {...props} />;
};

export default LazyImage;
