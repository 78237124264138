import React from "react";
import "./Footer.css";

const Footer = ({ backgroundColor = "#1d1626", color = "#fff" }) => {
  return (
    <footer className="footer-section" style={{ backgroundColor, color }}>
      <div className="container">
        <div className="footer-bottom">
          <p>&copy; 2024 Luccino. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
