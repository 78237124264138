import React, { useEffect, useState } from "react";
import "./AndyAguilarPortfolio.css";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../../business/languages/LanguageSwitcher";
import Footer from "../../../presentation/Footer/Footer";
import { Fade } from "react-reveal";

// Importar imágenes de eventos
import profileImage from "../../../images/AA/AndyAguilar.jfif";
import event1Image1 from "../../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00001.jpeg";
import event1Image2 from "../../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00002.jpeg";
import event1Image3 from "../../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00003.jpeg";
import event2Image1 from "../../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00001.jpeg";
import event2Image2 from "../../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00002.jpeg";
import event2Image3 from "../../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00003.jpeg";
import event2Image4 from "../../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00004.jpeg";
import event3Image1 from "../../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00001.jpeg";
import event3Image2 from "../../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00002.jpeg";
import event3Image3 from "../../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00003.jpeg";
import event3Image4 from "../../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00005.jpeg";
import event4Image1 from "../../../images/AA/nba-g-league_2024-07-28_0202/image00001.jpeg";
import event4Image2 from "../../../images/AA/nba-g-league_2024-07-28_0202/image00002.jpeg";
import event4Image3 from "../../../images/AA/nba-g-league_2024-07-28_0202/image00003.jpeg";
import event5Image1 from "../../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00009.jpeg";
import event5Image2 from "../../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00011.jpeg";
import event5Image3 from "../../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00007.jpeg";
import event5Image4 from "../../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00008.jpeg";
import event6Image1 from "../../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00002.jpeg";
import event6Image2 from "../../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00003.jpeg";
import event6Image3 from "../../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00004.jpeg";
import event6Image4 from "../../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00005.jpeg";
import { Link } from "react-router-dom";
import LazyImage from "../../../business/lazyImage/LazyImage";

// Loader component
const AndyAguilarPortfolio = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="andy-aguilar-portfolio-container">
      <LanguageSwitcher backgroundColor="#ffbe55" color="#fff" />
      <div className="andy-aguilar-portfolio-content">
        <Fade>
          <div className="profile-section">
            <h1>Andy Aguilar</h1>
            <img
              src={profileImage}
              alt="Andy Aguilar"
              className="profile-image"
            />
            <h2>{t("AndyAguilarPortfolio.AndyAguilarSubtitle")}</h2>
            <p>{t("AndyAguilarPortfolio.AndyAguilarPortfolioDescription")}</p>
          </div>
        </Fade>

        {/* Evento 1: Galería horizontal */}
        <Fade delay={100}>
          <div className="event-section">
            <h3>{t("AndyAguilarPortfolio.AndyAguilarEvent1Title")}</h3>
            <p>{t("AndyAguilarPortfolio.AndyAguilarEvent1Description")}</p>
            <div className="horizontal-gallery">
              <LazyImage img src={event1Image1} alt="Event 1 Image 1" />
              <LazyImage img src={event1Image2} alt="Event 1 Image 2" />
              <LazyImage img src={event1Image3} alt="Event 1 Image 3" />
            </div>
            <Link to="event/ciba-cup" className="view-more-button">
              {t("ViewGallery")}
            </Link>
          </div>
        </Fade>

        {/* Evento 2: Cuadrícula */}
        <Fade delay={100}>
          <div className="event-section">
            <h3>{t("AndyAguilarPortfolio.AndyAguilarEvent2Title")}</h3>
            <p>{t("AndyAguilarPortfolio.AndyAguilarEvent2Description")}</p>
            <div className="grid-gallery">
              <LazyImage img src={event2Image1} alt="Event 2 Image 1" />
              <LazyImage img src={event2Image2} alt="Event 2 Image 2" />
              <LazyImage img src={event2Image3} alt="Event 2 Image 3" />
              <LazyImage img src={event2Image4} alt="Event 2 Image 4" />
            </div>
            <Link to="event/fiba-3x3" className="view-more-button">
              {t("ViewGallery")}
            </Link>
          </div>
        </Fade>

        {/* Evento 3: Cuadrícula */}
        <Fade delay={100}>
          <div className="event-section">
            <h3>{t("AndyAguilarPortfolio.AndyAguilarEvent3Title")}</h3>
            <p>{t("AndyAguilarPortfolio.AndyAguilarEvent3Description")}</p>
            <div className="grid-gallery">
              <LazyImage img src={event3Image1} alt="Event 3 Image 1" />
              <LazyImage img src={event3Image2} alt="Event 3 Image 2" />
              <LazyImage img src={event3Image3} alt="Event 3 Image 3" />
              <LazyImage img src={event3Image4} alt="Event 3 Image 4" />
            </div>
            <Link to="event/mieleras-champs" className="view-more-button">
              {t("ViewGallery")}
            </Link>
          </div>
        </Fade>

        {/* Evento 4: Galería horizontal */}
        <Fade delay={100}>
          <div className="event-section">
            <h3>{t("AndyAguilarPortfolio.AndyAguilarEvent4Title")}</h3>
            <p>{t("AndyAguilarPortfolio.AndyAguilarEvent4Description")}</p>
            <div className="horizontal-gallery">
              <LazyImage img src={event4Image1} alt="Event 4 Image 1" />
              <LazyImage img src={event4Image2} alt="Event 4 Image 2" />
              <LazyImage img src={event4Image3} alt="Event 4 Image 3" />
            </div>
            <Link to="event/nba-g-league" className="view-more-button">
              {t("ViewGallery")}
            </Link>
          </div>
        </Fade>

        {/* Evento 5: Cuadrícula */}
        <Fade delay={100}>
          <div className="event-section">
            <h3>{t("AndyAguilarPortfolio.AndyAguilarEvent5Title")}</h3>
            <p>{t("AndyAguilarPortfolio.AndyAguilarEvent5Description")}</p>
            <div className="grid-gallery">
              <LazyImage img src={event5Image1} alt="Event 5 Image 1" />
              <LazyImage img src={event5Image2} alt="Event 5 Image 2" />
              <LazyImage img src={event5Image3} alt="Event 5 Image 3" />
              <LazyImage img src={event5Image4} alt="Event 5 Image 4" />
            </div>
            <Link to="event/fiba-u17" className="view-more-button">
              {t("ViewGallery")}
            </Link>
          </div>
        </Fade>

        {/* Evento 6: Cuadrícula */}
        <Fade delay={100}>
          <div className="event-section">
            <h3>{t("AndyAguilarPortfolio.AndyAguilarEvent6Title")}</h3>
            <p>{t("AndyAguilarPortfolio.AndyAguilarEvent6Description")}</p>
            <div className="grid-gallery">
              <LazyImage img src={event6Image1} alt="Event 6 Image 1" />
              <LazyImage img src={event6Image2} alt="Event 6 Image 2" />
              <LazyImage img src={event6Image3} alt="Event 6 Image 3" />
              <LazyImage img src={event6Image4} alt="Event 6 Image 4" />
            </div>
            <Link to="event/universiada" className="view-more-button">
              {t("ViewGallery")}
            </Link>
          </div>
        </Fade>
      </div>
      <Footer />
    </div>
  );
};

export default AndyAguilarPortfolio;
