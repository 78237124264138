import React from "react";
import logo from "../../images/logoAlt.png";
import "./Banner.css";
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const Banner = () => {
  const { t } = useTranslation();

  return (
    <div className="banner" id="banner">
      <div className="banner-overlay">
        <div className="banner-content">
          <Fade delay={700} duration={1000}>
            <div className="logo-container">
              <img src={logo} alt="Luccino Logo" />
            </div>
            <div className="banner-slogan-container">
              <p>{t("slogan")}</p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Banner;
