// En Home.js
import React from "react";
import Banner from "../Banner/Banner";
import About from "../About/About";
import Services from "../Services/Services";
import History from "../History/History";
import ProjectsCarousel from "../ProjectsCarousel/ProjectsCarousel";
import FAQ from "../FAQ/FAQ";
import Contact from "../Contact/Contact";
import SocialMedia from "../SocialMedia/SocialMedia";
import Footer from "../Footer/Footer";
import Quote from "../Quote/Quote";
import ScrollSpy from "../../business/ScrollSpy/ScrollSpy";
import LanguageSwitcher from "../../business/languages/LanguageSwitcher";
import MyPorTech from "../MyPorTech/MyPorTech";

const Home = () => {
  const sections = [
    { id: "banner", name: "Home" },
    { id: "about", name: "About" },
    { id: "services", name: "Services" },
    { id: "myportech", name: "MyPorTech" },
    { id: "history", name: "History" },
    { id: "projects", name: "Projects" },
    { id: "quote", name: "Quote" },
    { id: "faq", name: "FAQ" },
    { id: "contact", name: "Contact" },
    { id: "socialMedia", name: "Social Media" },
  ];

  return (
    <div className="home-container">
      <LanguageSwitcher />
      <ScrollSpy sections={sections} />
      <Banner />
      <About />
      <Services />
      <MyPorTech />
      <History />
      <ProjectsCarousel />
      <Quote />
      <FAQ />
      <Contact />
      <SocialMedia />
      <Footer />
    </div>
  );
};

export default Home;
