// Historia.jsx

import React, { useState } from "react";
import "./History.css";
import profilePhoto from "../../images/profile-pic.jpg";
import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";
import { useTranslation } from "react-i18next";

const History = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  const handleChangeVisibility = (visible) => {
    if (visible) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={handleChangeVisibility} partialVisibility>
      <section id="history" className="history-section">
        <div className="container">
          <h2 className="section-title">{t("historyTitle")}</h2>
          <div className="history-content">
            <div className="founder-info">
              <h3>{t("founderName")}</h3>
              <p>
                {t("founderRole")}
                <br />
                {t("founderEducation")}
              </p>
            </div>
            <div className="timeline">
              {isVisible && (
                <Fade>
                  <div className="timeline-container">
                    <div className="timeline-line"></div>
                    <div className="timeline-items">
                      <div className="timeline-item">
                        <div className="timeline-icon"></div>
                        <div className="timeline-content">
                          <h4>{t("ideaConceptionTitle")}</h4>
                          <p>{t("ideaConceptionDescription")}</p>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-icon"></div>
                        <div className="timeline-content">
                          <h4>{t("pandemicInspirationTitle")}</h4>
                          <p>{t("pandemicInspirationDescription")}</p>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-icon"></div>
                        <div className="timeline-content">
                          <h4>{t("firstProjectTitle")}</h4>
                          <p>{t("firstProjectDescription")}</p>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-icon"></div>
                        <div className="timeline-content">
                          <h4>{t("exchangeInGermanyTitle")}</h4>
                          <p>{t("exchangeInGermanyDescription")}</p>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-icon"></div>
                        <div className="timeline-content">
                          <h4>{t("futureVisionTitle")}</h4>
                          <p>{t("futureVisionDescription")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              )}
            </div>
          </div>
        </div>
      </section>
    </VisibilitySensor>
  );
};

export default History;
