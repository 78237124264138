import React, { useState } from "react";
import "./ScrollSpy.css";

const Scrollspy = ({ sections }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setIsOpen(false); // Cerrar el menú después de navegar
    }
  };

  return (
    <div className="scrollspy-container">
      <div className="scrollspy-icon" onClick={toggleMenu}>
        <span className="icon-table"></span>
      </div>
      {isOpen && (
        <div className="scrollspy-menu">
          {sections.map((section) => (
            <div
              key={section.id}
              className="scrollspy-item"
              onClick={() => scrollToSection(section.id)}
            >
              {section.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Scrollspy;
