import React, { useState } from "react";
import "./ProjectsCarousel.css";
import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";
import { useTranslation } from "react-i18next";

// Logos
import BrujasLogo from "../../images/brujas.jpg";
import SigmaLogo from "../../images/sigma.png";
import DraJanyLogo from "../../images/drjany.jpg";
import FUWALogo from "../../images/fuwa.png";
import JCLogo from "../../images/just-chilling.png";
import PanenkaLogo from "../../images/panenka.png";
import TSMLogo from "../../images/tsm.png";

const ProjectsCarousel = () => {
  const { t } = useTranslation();
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const [filters, setFilters] = React.useState({
    year: "All",
    type: "All",
    status: "All",
    owner: "All",
  });
  const [searchTerm, setSearchTerm] = React.useState("");

  const getProjectLogo = (id) => {
    switch (id) {
      case 1:
        return BrujasLogo;
      case 2:
        return SigmaLogo;
      case 3:
        return DraJanyLogo;
      case 4:
        return FUWALogo;
      case 5:
        return JCLogo;
      case 6:
        return PanenkaLogo;
      case 7:
        return TSMLogo;
      default:
        return "";
    }
  };

  const projects = t("projects", { returnObjects: true });

  const filteredProjects = projects.filter((project) => {
    return (
      (filters.year === "All" || project.year === filters.year) &&
      (filters.type === "All" || project.type === filters.type) &&
      (filters.status === "All" || project.status === filters.status) &&
      (filters.owner === "All" || project.owner === filters.owner) &&
      (searchTerm === "" ||
        project.title.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const projectItems = filteredProjects.map((project) => (
    <div
      key={project.id}
      className="project-item"
      onClick={() => handleProjectClick(project)}
    >
      <img
        src={getProjectLogo(project.id)}
        alt={project.title}
        className="project-logo"
      />
      <h5>{project.title}</h5>
    </div>
  ));

  // Función para manejar la visibilidad del componente
  const handleChangeVisibility = (visible) => {
    if (visible) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={handleChangeVisibility} partialVisibility>
      <section id="projects" className="projects-section">
        <div className="container">
          <Fade when={isVisible}>
            <div>
              <h2 className="section-title">{t("projectsTitle")}</h2>
              <p>{t("projectsDescription")}</p>
              <div className="filters">
                <select
                  onChange={(e) =>
                    setFilters({ ...filters, year: e.target.value })
                  }
                >
                  <option value="All">{t("allYears")}</option>
                  {Array.from(
                    new Set(projects.map((project) => project.year))
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <select
                  onChange={(e) =>
                    setFilters({ ...filters, type: e.target.value })
                  }
                >
                  <option value="All">{t("allTypes")}</option>
                  {Array.from(
                    new Set(projects.map((project) => project.type))
                  ).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="search">
                <input
                  type="text"
                  placeholder={t("searchPlaceholder")}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </Fade>

          <div className="projects-list-horizontal">{projectItems}</div>

          {selectedProject && (
            <div className="project-container">
              <div
                key={selectedProject.id}
                className="project-item selected-project"
              >
                <p className="owner-label">
                  {selectedProject.owner === "Luccino"
                    ? t("luccinosProject")
                    : t("clientsProject")}
                </p>
                <p className="project-type">{selectedProject.type}</p>
                <img
                  src={getProjectLogo(selectedProject.id)}
                  alt={selectedProject.title}
                  className="project-logo"
                />
                <h3>{selectedProject.title}</h3>
                <p>{selectedProject.year}</p>
                <p>{selectedProject.description}</p>
                <div className="technologies">
                  {selectedProject.technologies.map((tech, index) => (
                    <span key={index} className="technology">
                      {tech}
                    </span>
                  ))}
                </div>
                <p>
                  {t("status")}: <br /> {selectedProject.status}
                </p>
                {selectedProject.url && (
                  <a
                    href={selectedProject.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="project-link"
                  >
                    {t("visitProject")}
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </VisibilitySensor>
  );
};

export default ProjectsCarousel;
