import React, { useState } from "react";
import "./FAQ.css"; // Asegúrate de tener un archivo CSS para este componente
import Fade from "react-reveal/Fade"; // Importa el efecto de animación
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();
  const [selectedFAQ, setSelectedFAQ] = useState(null);

  // List of questions and answers
  const faqs = t("faqs", { returnObjects: true });

  const handleFAQClick = (faq) => {
    setSelectedFAQ(selectedFAQ === faq.id ? null : faq.id);
  };

  return (
    <section id="faq" className="faq-section">
      <div className="container">
        <h2 className="section-title">{t("faqSectionTitle")}</h2>

        <div className="faq-list">
          {faqs.map((faq) => (
            <Fade key={faq.id} bottom cascade>
              <div
                className={`faq-item ${
                  selectedFAQ === faq.id ? "selected" : ""
                }`}
                onClick={() => handleFAQClick(faq)}
              >
                <h5>{faq.question}</h5>
                {selectedFAQ === faq.id && (
                  <p className="faq-answer">{faq.answer}</p>
                )}
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
