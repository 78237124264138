import React, { useEffect } from "react";
import "./MyPorTech.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "../business/languages/LanguageSwitcher";
import Footer from "../presentation/Footer/Footer";

import img1 from "../images/AA/AndyAguilar.jfif";

const MyPorTech = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const portfolios = [
    {
      id: 1,
      title: t("Portfolio1Title"),
      subtitle: t("Portfolio1Subtitle"),
      image: img1,
      url: "/myportech/andy-aguilar",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavigate = (url) => {
    navigate(url);
  };

  return (
    <div className="my-portech-container" id="myportech">
      <LanguageSwitcher />
      <div className="myportech-content">
        <h2>{t("PorTechTitle")}</h2>
        <p>{t("PorTechIntro")}</p>
        <p>{t("PorTechIntro2")}</p>
        <div className="portfolios-grid">
          {portfolios.map((portfolio) => (
            <div key={portfolio.id} className="portfolio-card">
              <h3>{portfolio.title}</h3>
              <img src={portfolio.image} alt={portfolio.title} />
              <h4>{portfolio.subtitle}</h4>
              <p>{portfolio.description}</p>
              <button
                className="portfolio-button"
                onClick={() => handleNavigate(portfolio.url)}
              >
                {t("ViewPortfolio")}
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyPorTech;
