// En src/components/MyPorTech/MyPorTech.js
import React from "react";
import "./MyPorTech.css";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Importa imágenes de ejemplo
import themeImg from "../../images/layout.png";
import interactiveImg from "../../images/images.png";
import socialImg from "../../images/share.png";

const MyPorTech = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/myportech");
  };

  const features = [
    { img: themeImg, text: t("FI-F1") },
    { img: interactiveImg, text: t("FI-F2") },
    { img: socialImg, text: t("FI-F3") },
  ];

  return (
    <div className="myportech-container" id="myportech">
      <Fade>
        <div className="myportech-content">
          <h2>{t("PorTechTitle")}</h2>
          <p>{t("PT-Subtitle")}</p>
          <div className="myportech-features">
            {features.map((feature, index) => (
              <div key={index} className="feature-card">
                <img src={feature.img} alt={`feature-${index}`} />
                <p>{feature.text}</p>
              </div>
            ))}
          </div>
          <button className="myportech-button" onClick={handleButtonClick}>
            {t("PT-BTN")}
          </button>
        </div>
      </Fade>
    </div>
  );
};

export default MyPorTech;
