import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./EventGallery.css";

// Importar todas las imágenes necesarias
import astros1 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00001.jpeg";
import astros2 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00002.jpeg";
import astros3 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00003.jpeg";
import astros4 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00004.jpeg";
import astros5 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00005.jpeg";
import astros6 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00006.jpeg";
import astros7 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00007.jpeg";
import astros8 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00008.jpeg";
import astros9 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00009.jpeg";
import astros10 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00010.jpeg";
import astros11 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00011.jpeg";
import astros12 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00012.jpeg";
import astros13 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00013.jpeg";
import astros14 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00014.jpeg";
import astros15 from "../../images/AA/astros-vs-angeles-cdmx-cibacopa_2024-07-28_0209/image00015.jpeg";
import fiba1 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00001.jpeg";
import fiba2 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00002.jpeg";
import fiba3 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00003.jpeg";
import fiba4 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00004.jpeg";
import fiba5 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00005.jpeg";
import fiba6 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00006.jpeg";
import fiba7 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00007.jpeg";
import fiba8 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00008.jpeg";
import fiba9 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00009.jpeg";
import fiba10 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00010.jpeg";
import fiba11 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00011.jpeg";
import fiba12 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00012.jpeg";
import fiba13 from "../../images/AA/fiba-3x3-youth-nations-league_2024-07-28_0206/image00013.jpeg";
import mieleras1 from "../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00001.jpeg";
import mieleras2 from "../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00002.jpeg";
import mieleras3 from "../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00003.jpeg";
import mieleras4 from "../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00004.jpeg";
import mieleras5 from "../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00005.jpeg";
import mieleras6 from "../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00006.jpeg";
import mieleras7 from "../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00007.jpeg";
import mieleras8 from "../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00008.jpeg";
import mieleras9 from "../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00009.jpeg";
import mieleras10 from "../../images/AA/mieleras-champs-lmbpf_2024-07-28_0203/image00010.jpeg";
import gleague1 from "../../images/AA/nba-g-league_2024-07-28_0202/image00001.jpeg";
import gleague2 from "../../images/AA/nba-g-league_2024-07-28_0202/image00002.jpeg";
import gleague3 from "../../images/AA/nba-g-league_2024-07-28_0202/image00003.jpeg";
import gleague4 from "../../images/AA/nba-g-league_2024-07-28_0202/image00004.jpeg";
import gleague5 from "../../images/AA/nba-g-league_2024-07-28_0202/image00005.jpeg";
import gleague6 from "../../images/AA/nba-g-league_2024-07-28_0202/image00006.jpeg";
import gleague7 from "../../images/AA/nba-g-league_2024-07-28_0202/image00007.jpeg";
import gleague8 from "../../images/AA/nba-g-league_2024-07-28_0202/image00008.jpeg";
import gleague9 from "../../images/AA/nba-g-league_2024-07-28_0202/image00009.jpeg";
import gleague10 from "../../images/AA/nba-g-league_2024-07-28_0202/image00010.jpeg";
import gleague11 from "../../images/AA/nba-g-league_2024-07-28_0202/image00011.jpeg";
import gleague12 from "../../images/AA/nba-g-league_2024-07-28_0202/image00012.jpeg";
import gleague13 from "../../images/AA/nba-g-league_2024-07-28_0202/image00013.jpeg";
import fibau1 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00001.jpeg";
import fibau2 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00002.jpeg";
import fibau3 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00003.jpeg";
import fibau4 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00004.jpeg";
import fibau5 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00005.jpeg";
import fibau6 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00006.jpeg";
import fibau7 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00007.jpeg";
import fibau8 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00008.jpeg";
import fibau9 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00009.jpeg";
import fibau10 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00010.jpeg";
import fibau11 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00011.jpeg";
import fibau12 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00012.jpeg";
import fibau13 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00013.jpeg";
import fibau14 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00014.jpeg";
import fibau15 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00015.jpeg";
import fibau16 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00016.jpeg";
import fibau17 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00017.jpeg";
import fibau18 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00018.jpeg";
import fibau19 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00019.jpeg";
import fibau20 from "../../images/AA/mundial-fiba-u17-leon-guanajuato_2024-07-28_0208/image00020.jpeg";
import univer1 from "../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00001.jpeg";
import univer2 from "../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00002.jpeg";
import univer3 from "../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00003.jpeg";
import univer4 from "../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00004.jpeg";
import univer5 from "../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00005.jpeg";
import univer6 from "../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00006.jpeg";
import univer7 from "../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00007.jpeg";
import univer8 from "../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00008.jpeg";
import univer9 from "../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00009.jpeg";
import univer10 from "../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00010.jpeg";
import univer11 from "../../images/AA/universiada-2024-aguascalientes_2024-07-28_0204/image00011.jpeg";
import LazyImage from "../../business/lazyImage/LazyImage";

// Objeto que contiene todas las imágenes organizadas por evento
const images = {
  "ciba-cup": [
    astros1,
    astros2,
    astros3,
    astros4,
    astros5,
    astros6,
    astros7,
    astros8,
    astros9,
    astros10,
    astros11,
    astros12,
    astros13,
    astros14,
    astros15,
  ],
  "fiba-3x3": [
    fiba1,
    fiba2,
    fiba3,
    fiba4,
    fiba5,
    fiba6,
    fiba7,
    fiba8,
    fiba9,
    fiba10,
    fiba11,
    fiba12,
    fiba13,
  ],
  "mieleras-champs": [
    mieleras1,
    mieleras2,
    mieleras3,
    mieleras4,
    mieleras5,
    mieleras6,
    mieleras7,
    mieleras8,
    mieleras9,
    mieleras10,
  ],
  "nba-g-league": [
    gleague1,
    gleague2,
    gleague3,
    gleague4,
    gleague5,
    gleague6,
    gleague7,
    gleague8,
    gleague9,
    gleague10,
    gleague11,
    gleague12,
    gleague13,
  ],
  "fiba-u17": [
    fibau1,
    fibau2,
    fibau3,
    fibau4,
    fibau5,
    fibau6,
    fibau7,
    fibau8,
    fibau9,
    fibau10,
    fibau11,
    fibau12,
    fibau13,
    fibau14,
    fibau15,
    fibau16,
    fibau17,
    fibau18,
    fibau19,
    fibau20,
  ],
  "universiada-2024": [
    univer1,
    univer2,
    univer3,
    univer4,
    univer5,
    univer6,
    univer7,
    univer8,
    univer9,
    univer10,
    univer11,
  ],
};

// Loader component
const Loader = () => (
  <div className="loader-container">
    <div className="loader"></div>
  </div>
);

const EventGallery = () => {
  const { t } = useTranslation();
  const { eventName } = useParams();
  const [loading, setLoading] = useState(true);
  const [eventImages, setEventImages] = useState([]);

  useEffect(() => {
    // Simular una carga de imágenes
    const loadImages = async () => {
      const imagesToLoad = images[eventName] || [];
      await Promise.all(
        imagesToLoad.map((src) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve;
            img.onerror = reject;
          });
        })
      );
      setEventImages(imagesToLoad);
      setLoading(false);
    };

    loadImages();
  }, [eventName]);

  return (
    <div className="event-gallery-container">
      {loading ? (
        <Loader />
      ) : eventImages.length > 0 ? (
        eventImages.map((image, index) => (
          <div key={index} className="parallax">
            <LazyImage
              img
              src={image}
              alt={`Event ${eventName} Image ${index + 1}`}
            />
          </div>
        ))
      ) : (
        <p>{t("No images found for this event.")}</p>
      )}
    </div>
  );
};

export default EventGallery;
