import React, { useState } from "react";
import "./Contact.css";
import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  const handleWhatsAppClick = () => {
    const message = encodeURIComponent(t("whatsappMessage"));
    const recipient = "5654326688"; // Reemplaza con tu número de WhatsApp
    window.location.href = `https://wa.me/${recipient}?text=${message}`;
  };

  const handleChangeVisibility = (visible) => {
    if (visible) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={handleChangeVisibility} partialVisibility>
      <section id="contact" className="contact-section">
        <div className="container">
          <h2 className="section-title">{t("contactSectionTitle")}</h2>
          <div className="contact-methods">
            {isVisible && (
              <Fade bottom>
                <div className="email">
                  <span className="icon-mail"></span>
                  <h4>{t("email.title")}</h4>
                  <p>{t("email.description")}</p>
                  <a href="mailto:info@example.com" className="contact-link">
                    {t("email.link")}
                  </a>
                </div>
                <div className="whatsapp">
                  <span className="icon-whatsapp"></span>
                  <h4>{t("whatsapp.title")}</h4>
                  <p>{t("whatsapp.description")}</p>
                  <button
                    className="contact-button"
                    onClick={handleWhatsAppClick}
                  >
                    {t("whatsapp.buttonText")}
                  </button>
                </div>
              </Fade>
            )}
          </div>
        </div>
      </section>
    </VisibilitySensor>
  );
};

export default Contact;
